import { useEffect, useState } from 'react'
import { useAssociateStore, useUserStore } from './DataStore'

import styled from 'styled-components'
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  pdf,
  Font,
} from '@react-pdf/renderer'

import { toast } from 'react-toastify'

import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'
import { Sidebar } from 'primereact/sidebar'
import { InputTextarea } from 'primereact/inputtextarea'

import saveAs from 'file-saver'
import axios from 'axios'
import qs from 'qs'

const docStyles = StyleSheet.create({
  page: {
    display: 'flex',
    flexDirection: 'column',
    padding: '10pt',
    fontSize: '12pt',
    lineHeight: 1.5,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: '10px',
  },
  card: {
    marginRight: '2px',
    marginTop: '2px',
    display: 'flex',
    width: '33%',
    height: 'auto',
    padding: '5px',
    border: '1px solid black',
  }
})

const Comms = () => {
  const associates = useAssociateStore((state: any) => state.associates)
  const fetchAssociates = useAssociateStore((state: any) => state.fetch)
  const managing_zc = useUserStore((state: any) => state.managing_zc)
  const userUID = useUserStore((state: any) => state.uid)
  const availableSmsCount = useUserStore((state: any) => state.available_sms)
  const updateSmsPackage = useUserStore((state: any) => state.updateSmsPackage)

  const [isSendingSms, setIsSendingSms] = useState(false)
  const [usersToSend, setUsersToSend] = useState<any>([])
  const [smsValue, setSmsValue] = useState('')

  useEffect(() => {
    if (!associates) {
      fetchAssociates(managing_zc)
    }
  }, [associates, fetchAssociates, managing_zc])

  const splitArray = (array: Array<any>, size: number) => {
    let result = []
    for (let i = 0; i < array.length; i += size) {
      let chunk = array.slice(i, i + size)
      result.push(chunk)
    }
    return result
  }

  Font.registerHyphenationCallback(word => [word]);

  const DocumentPdf = ({ arr }: any) => (
    <Document>
      <Page size="A4" style={docStyles.page}>
        {splitArray(arr, 3).map((row: any) => {
          return (
            <View style={docStyles.row}>
              {row.map((socio: any) => {
                return (
                  <View style={docStyles.card}>
                    <View break><Text>{socio.name}</Text></View>
                    <View break><Text>{socio.address}</Text></View>  
                    {socio.address_2 && <View break><Text>{socio.address_2}</Text></View>}
                  </View>
                )
              })}
            </View>
          )
        })}
      </Page>
    </Document>
  )

  const downloadPdf = async (arr: any) => {
    const blob = await pdf(<DocumentPdf arr={arr} />).toBlob()
    saveAs(blob, arr.length > 1 ? 'Etiquetas Sócios' : 'Etiqueta Sócio')
  }

  const sendSms = () => {
    if (availableSmsCount - usersToSend.length > 0) {
      setIsSendingSms(false)

      usersToSend.forEach((user: any) => {
        axios
          .post(
            `https://api.twilio.com/2010-04-01/Accounts/${process.env.REACT_APP_TWILIO_SID}/Messages.json`,
            qs.stringify({
              Body: smsValue,
              To: `+351${user.phone}`,
              From: process.env.REACT_APP_TWILIO_PH,
            }),
            {
              auth: {
                username: process.env.REACT_APP_TWILIO_SID as string,
                password: process.env.REACT_APP_TWILIO_TOKEN as string,
              },
            }
          )
          .then((_res) => {
            toast.success(`Mensagem Enviada a ${user.name}`, {
              autoClose: 3000,
            })
          })
          .catch((_err) => {
            toast.error('Ocorreu um erro, tente novamente!')
          })
      })

      updateSmsPackage(availableSmsCount - usersToSend.length, userUID)
      setSmsValue('')
      setUsersToSend([])
    } else {
      toast.error('Pacote de SMS insuficiente!')
    }
  }

  const createAddressTemplate = (rowData: any) => (
    <Button
      size="small"
      icon="pi pi-envelope"
      severity="info"
      onClick={() => downloadPdf([rowData])}
    />
  )

  const sendSmsTemplate = (rowData: any) => (
    <Button
      size="small"
      icon="pi pi-phone"
      severity="info"
      onClick={() => {
        setUsersToSend([rowData])
        setIsSendingSms(true)
      }}
    />
  )

  const tableHeader = (
    <>
      <Button
        size="small"
        style={{ marginRight: '10px', marginBottom: '10px' }}
        label="Gerar Etiquetas Geral"
        severity="info"
        onClick={() => downloadPdf(associates)}
      />
      <Button
        size="small"
        style={{ marginRight: '10px', marginBottom: '10px' }}
        label="Eviar SMS Geral"
        severity="info"
        onClick={() => {
          setUsersToSend(associates)
          setIsSendingSms(true)
        }}
      />
      <Button
        size="small"
        style={{ marginRight: '10px', marginBottom: '10px' }}
        label={`Pacote de SMS: ${availableSmsCount}`}
        severity="help"
        onClick={() => {}}
      />
    </>
  )

  return (
    <Wrapper>
      <Sidebar
        visible={isSendingSms}
        position="right"
        onHide={() => {
          setIsSendingSms(false)
          setSmsValue('')
          setUsersToSend([])
        }}
        style={{ width: '500px' }}
      >
        <h2>Enviar SMS</h2>
        <p>{`Mensagem para ${
          usersToSend.length === 1 ? usersToSend[0].name : 'todos os sócios'
        }`}</p>
        <InputTextarea
          placeholder="Mensagem"
          style={{ width: '100%' }}
          autoResize
          value={smsValue}
          onChange={(e) => setSmsValue(e.target.value)}
          rows={5}
          cols={30}
        />
        <StyledCharCount
          count={smsValue.length}
        >{`${smsValue.length} de 160`}</StyledCharCount>
        <Button
          size="small"
          disabled={!(smsValue.length <= 160)}
          style={{ marginTop: '10px', width: '100%' }}
          label="Eviar SMS"
          severity="info"
          onClick={() => sendSms()}
        />
      </Sidebar>
      <DataTable
        value={associates}
        stripedRows
        size="normal"
        scrollable
        scrollHeight="76vh"
        header={tableHeader}
      >
        <Column sortable field="name" header="Nome" />
        <Column field="ass_nr" header="Número de Sócio" />
        <Column field="phone" header="Telefone" />
        <Column field="email" header="Email" />
        <Column body={createAddressTemplate} header="Gerar Etiqueta" />
        <Column body={sendSmsTemplate} header="Enviar SMS" />
      </DataTable>
    </Wrapper>
  )
}

export default Comms

const Wrapper = styled.div`
  padding: 10px;
`

const StyledCharCount = styled.p<{ count: number }>`
  font-weight: bold;
  color: ${(props) =>
    props.count > 0 && props.count <= 160 ? 'green' : 'red'};
`
