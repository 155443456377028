import { useState, useEffect } from 'react'

import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { Button } from 'primereact/button'

import styled from 'styled-components'
import { Sidebar } from 'primereact/sidebar'
import { InputText } from 'primereact/inputtext'
import { InputNumber } from 'primereact/inputnumber'
import { TabMenu } from 'primereact/tabmenu'

import { toast } from 'react-toastify'

import { useAssociateStore, useUserStore, useZCStore } from './DataStore'

const socioFormInitial = {
  name: '',
  license_id: undefined,
  quota: undefined,
  civil_id: undefined,
  phone: undefined,
  email: '',
  address: '',
  address_2: '',
  ass_nr: undefined,
}

const Associates = () => {
  const associates = useAssociateStore((state: any) => state.associates)
  const fetchAssociates = useAssociateStore((state: any) => state.fetch)
  const deleteAssociate = useAssociateStore((state: any) => state.delete)
  const addAssociate = useAssociateStore((state: any) => state.add)
  const updateAssociate = useAssociateStore((state: any) => state.update)
  const assContracted = useZCStore((state: any) => state.ass_contracted)

  const managing_zc = useUserStore((state: any) => state.managing_zc)

  const [addSocio, setAddSocio] = useState(false)
  const [editSocio, setEditSocio] = useState(false)

  const [socioForm, setSocioForm] = useState(socioFormInitial)

  useEffect(() => {
    if (!associates) {
      fetchAssociates(managing_zc)
    }
  }, [associates, fetchAssociates, managing_zc])

  const isFormValid = () => {
    const temp = Object.values(socioForm).filter((item) => item)

    let result = false

    if (temp.length < Object.keys(socioFormInitial).length) {
      toast.error('Tem de preencher todos os campos')
      result = false
    } else {
      result = true
    }

    return result
  }

  const editBodyTemplate = (rowData: any) => (
    <Button
      size="small"
      icon="pi pi-pencil"
      severity="info"
      onClick={() => {
        setSocioForm(rowData)
        setEditSocio(true)
      }}
    />
  )

  const deleteBodyTemplate = (rowData: any) => (
    <Button
      size="small"
      icon="pi pi-times"
      severity="danger"
      onClick={() => deleteAssociate(rowData.license_id, managing_zc)}
    />
  )

  const tableHeader = (
    <ActionContainer>
      <TabMenu
        style={{ pointerEvents: 'none' }}
        model={[
          {
            label: `Total de Sócios: ${(associates || []).length}`,
            icon: 'pi pi-fw pi-users',
          },
        ]}
        activeIndex={0}
      />
      <Button
        label={'Adicionar Sócio'}
        icon="pi pi-plus"
        onClick={() => {
          if (associates.length < Number(assContracted)) {
            setAddSocio(true)
            return
          }

          toast.error(
            'Atingiu o limite máximo de Sócios contratados, contacte geral@sercacador.com'
          )
        }}
      />
    </ActionContainer>
  )

  const actionLabel = `${addSocio ? 'Adicionar' : 'Editar'} Sócio`
  return (
    <Wrapper>
      <Sidebar
        visible={addSocio || editSocio}
        position="right"
        onHide={() => {
          setAddSocio(false)
          setEditSocio(false)
          setSocioForm(socioFormInitial)
        }}
        style={{ width: '500px' }}
      >
        <h2>{actionLabel}</h2>
        <StyledP>Nome</StyledP>
        <InputText
          style={{ width: '100%' }}
          value={socioForm.name}
          placeholder="Nome"
          onChange={(e) => setSocioForm({ ...socioForm, name: e.target.value })}
        />
        <StyledP>Número de Sócio</StyledP>
        <InputNumber
          style={{ width: '100%' }}
          useGrouping={false}
          value={socioForm.ass_nr}
          placeholder="Número de Sócio"
          onChange={(e) =>
            setSocioForm({ ...socioForm, ass_nr: String(e.value) as any })
          }
        />
        <StyledP>Quota</StyledP>
        <InputNumber
          style={{ width: '100%' }}
          useGrouping={false}
          value={socioForm.quota}
          placeholder="Quota"
          onChange={(e) =>
            setSocioForm({ ...socioForm, quota: String(e.value) as any })
          }
        />
        <StyledP>Carta de Caçador</StyledP>
        <InputNumber
          style={{ width: '100%' }}
          useGrouping={false}
          value={socioForm.license_id}
          placeholder="Número Carta Caçador"
          onChange={(e) =>
            setSocioForm({ ...socioForm, license_id: String(e.value) as any })
          }
        />
        <StyledP>Identificação Civil</StyledP>
        <InputNumber
          style={{ width: '100%' }}
          useGrouping={false}
          value={socioForm.civil_id}
          placeholder="Identificação Civil"
          onChange={(e) =>
            setSocioForm({ ...socioForm, civil_id: String(e.value) as any })
          }
        />
        <StyledP>Telefone</StyledP>
        <InputNumber
          style={{ width: '100%' }}
          useGrouping={false}
          value={socioForm.phone}
          placeholder="Telefone"
          onChange={(e) =>
            setSocioForm({ ...socioForm, phone: String(e.value) as any })
          }
        />
        <StyledP>Email</StyledP>
        <InputText
          style={{ width: '100%' }}
          value={socioForm.email}
          placeholder="Email"
          onChange={(e) =>
            setSocioForm({ ...socioForm, email: e.target.value })
          }
        />
        <StyledP>Rua</StyledP>
        <InputText
          style={{ width: '100%' }}
          value={socioForm.address}
          placeholder="Rua"
          onChange={(e) =>
            setSocioForm({ ...socioForm, address: e.target.value })
          }
        />
        <p>Código Postal</p>
        <InputText
          style={{ width: '100%' }}
          value={socioForm.address_2}
          placeholder="Código Postal"
          onChange={(e) =>
            setSocioForm({ ...socioForm, address_2: e.target.value })
          }
        />
        <Button
          style={{ width: '100%', marginTop: '20px' }}
          label={actionLabel}
          onClick={() => {
            if (isFormValid()) {
              if (addSocio) {
                addAssociate(socioForm, managing_zc)
              }

              if (editSocio) {
                updateAssociate(socioForm)
              }

              setSocioForm(socioFormInitial)
              setAddSocio(false)
              setEditSocio(false)
            }
          }}
        />
      </Sidebar>
      <DataTable
        value={associates}
        stripedRows
        size="normal"
        scrollable
        scrollHeight="75vh"
        header={tableHeader}
      >
        <Column sortable field="name" header="Nome" />
        <Column
          sortable
          field="entry_date"
          body={(rowData: any) =>
            new Date(rowData.entry_date).toLocaleDateString('pt-PT')
          }
          header="Data Admissão"
        />
        <Column sortable field="ass_nr" header="Número de Sócio" />
        <Column field="license_id" header="Número Licença" />
        <Column
          body={(rowData: any) =>
            rowData.license_expiry ? new Date(rowData.license_expiry).toLocaleDateString('pt-PT') : '-'
          }
          header="Validade"
        />
        <Column field="civil_id" header="Identificação Civil" />
        <Column field="phone" header="Telefone" />
        <Column field="email" header="Email" />
        <Column
          bodyStyle={{ textAlign: 'center' }}
          body={editBodyTemplate}
          header="Editar Sócio"
        />
        <Column
          bodyStyle={{ textAlign: 'center' }}
          body={deleteBodyTemplate}
          header="Remover Sócio"
        />
      </DataTable>
    </Wrapper>
  )
}

export default Associates

const Wrapper = styled.div`
  padding: 10px;
`
const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
const StyledP = styled.p`
  margin: 10px 0 0 0;
`
